import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SimpleCacheService {

  constructor(
    private http: HttpClient
  ) {

  }

  get(key: string): Promise<any> {

    return new Promise<any>((resolve, reject) => {

      this.http.get('https://utilities1.gointeract.io/ivr/api/SimpleCaches/get?key=' + key).subscribe((res) => {

        resolve(res);

      });

    });

  }

}
