import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ConversationTriggerService {


  private endpoint = 'https://utilities1.gointeract.io/interact_ai/api/BotHelpers/sendBotCommand';


  constructor(
    private httpClient: HttpClient
  ) {
  }


  sendHiddenText(conversationId: string, text: string): Observable<object> {

    console.log('trigger_hidden %s send to conversationId %s', text, conversationId);

    return this.httpClient.post(this.endpoint, {
      conversationId: conversationId,
      command: 'trigger_hidden',
      payload: {
        message: text
      }
    });

  }

  sendText(conversationId: string, text: string, asBot: boolean): Observable<object> {

    let command = 'show_text';
    if (!asBot) {
      command = 'show_text_agent';
    }

    return this.httpClient.post(this.endpoint, {
      conversationId: conversationId,
      command: command,
      payload: {
        message: text
      }
    });

  }

}
