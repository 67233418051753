import {Component} from '@angular/core';

import {DomSanitizer} from '@angular/platform-browser';
import {MatIconRegistry} from '@angular/material/icon';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'Jacada Desktop Screen-Pop';

  constructor(private activatedRoute: ActivatedRoute, private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer) {

    if (document.location.href.indexOf('agent?=') > -1) {
      document.location.href = document.location.href.replace('agent?=', 'agent/');
    }

    if (document.location.href.indexOf('agent/?=') > -1) {
      document.location.href = document.location.href.replace('agent/?=', 'agent/');
    }


    this.matIconRegistry.addSvgIcon(
      'neutral',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/neutral.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'happy',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/happy.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'sad',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/sad.svg')
    );

  }

}
