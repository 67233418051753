import { Component, OnInit } from '@angular/core';
import {PubNubAngular} from 'pubnub-angular2';
import {animate, style, transition, trigger} from '@angular/animations';

@Component({
  selector: 'app-simple-voice-recognizer',
  templateUrl: './simple-voice-recognizer.component.html',
  styleUrls: ['./simple-voice-recognizer.component.scss'],
  animations: [
    trigger('items', [
      transition(':enter', [
        style({transform: 'scale(0.5)', opacity: 0}),  // initial
        animate('0.5s cubic-bezier(.8, -0.6, 0.2, 1.5)',
          style({transform: 'scale(1)', opacity: 1}))  // final
      ])
    ])
  ]
})
export class SimpleVoiceRecognizerComponent implements OnInit {

  interimMessages : Array<string> = new Array<string>();
  intentRecognized: Array<any> = new Array<any>();


  constructor(private pubnub: PubNubAngular) {
    this.pubnub.init({
      publishKey: 'pub-c-9ef8635f-8eae-4a07-adb8-515edd926442',
      subscribeKey: 'sub-c-2ebd149c-7e1c-11e5-9e96-02ee2ddab7fe',
      ssl: true
    });

  }

  ngOnInit() {

    const channels = ['Speech Intent Recognizer - all'];

    this.pubnub.subscribe({
      channels: channels,
      triggerEvents: ['message']
    });

    this.pubnub.getMessage(channels, (event) => {

      console.log(event.message);

      if (event.message.interimText) {
        this.interimMessages.push(event.message.interimText);
      } else if (event.message.intent) {
        this.intentRecognized.push(event.message);
        setTimeout(()=> {
          this.interimMessages = new Array<string>();
        }, 2500);
      }

    });

  }

}
