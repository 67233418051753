import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ServiceCodeService} from '../../../../service-code.service';
import {EventDistributerService} from '../../../../event-distributer.service';

@Component({
  selector: 'app-quick-links',
  templateUrl: './quick-links.component.html',
  styleUrls: ['./quick-links.component.scss']
})
export class QuickLinksComponent implements OnInit {

  automations = new Array<any>();

  spui = '';

  constructor(private activatedRoute: ActivatedRoute,
              private serviceCodeService: ServiceCodeService,
              private events: EventDistributerService) {

    if (this.activatedRoute.snapshot.paramMap.get('spui').length > 10) {
      console.log('spui parameter will be handled as conversation ID.');
      this.serviceCodeService.findServiceCodeByConversationId(this.activatedRoute.snapshot.paramMap.get('spui')).then((res) => {
        this.spui = res.spui;
        this.setup(this.spui);
      });

    } else {
      this.spui = activatedRoute.snapshot.paramMap.get('spui');
      this.setup(this.spui);
    }

  }

  ngOnInit() {
  }


  /*
      TODO get it dynamically
   */
  setup(spui: string) {
    this.automations.push({
      name: 'Password Vault',
      url: 'https://gointeract.io/interact/index?interaction=01ae38724cdd-9f01ed5d52ec52d9-0bd9&accountId=democenter_df&appkey=b3adb86e-d0a1-4b1f-86ba-a25d75ea7155&Environment-Name=Dev',
      hidden: false
    }, {
      name: 'Change Policy',
      url: 'https://gointeract.io/interact/index?interaction=a7e517822ce0-89d3125157fd72aa-dbfb&accountId=democenter_df&appkey=b3adb86e-d0a1-4b1f-86ba-a25d75ea7155&Environment-Name=Dev',
      hidden: false
    }, {
      name: 'Recent Statements',
      url: 'https://gointeract.io:443/interact/index?interaction=683e89b972fd-0723152209dd369e-15d3&accountId=jacada_germany&appkey=52d9b36fe4b0bd1d2d1ff9af&Environment-Name=Dev',
      hidden: false
    }, {
      name: 'Paperless Billing',
      url: 'https://gointeract.io:443/interact/index?interaction=683e89b972fd-0723152209dd369e-15d3&accountId=jacada_germany&appkey=52d9b36fe4b0bd1d2d1ff9af&Environment-Name=Dev',
      hidden: false
    }, {
      name: 'Pay Bill',
      url: 'https://gointeract.io:443/interact/index?interaction=683e89b972fd-0723152209dd369e-15d3&accountId=jacada_germany&appkey=52d9b36fe4b0bd1d2d1ff9af&Environment-Name=Dev',
      hidden: false
    }, {
      name: 'Rental Car Coverage',
      url: 'https://gointeract.io:443/interact/index?interaction=683e89b972fd-0723152209dd369e-15d3&accountId=jacada_germany&appkey=52d9b36fe4b0bd1d2d1ff9af&Environment-Name=Dev',
      hidden: false
    }, {
      name: 'Claim Status',
      url: 'https://gointeract.io:443/interact/index?interaction=683e89b972fd-0723152209dd369e-15d3&accountId=jacada_germany&appkey=52d9b36fe4b0bd1d2d1ff9af&Environment-Name=Dev',
      hidden: false
    });
  }

  startAutomation(automation: any): void {

    const automationEvent = {
      name: 'AddAutomation',
      id: automation.name,
      header: automation.name,
      url: automation.url
    };

    this.events.emit(automationEvent);

  }



}
