import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ServiceCodeService {

  private baseUrl = 'https://utilities1.gointeract.io/ivr/api/';
  //private baseUrl = 'http://localhost:3000/ivr/api/';

  constructor(
    private http: HttpClient
  ) {



  }


  findServiceCodeByConversationId(conversationId: string): Promise<any> {

    const query = {
      where: {
        conversationId: conversationId
      }
    };


    return new Promise((resolve, reject) => {

      this.http.get(this.baseUrl + 'ServiceCodes?filter=' + JSON.stringify(query)).subscribe((res: any) => {
       resolve(res[0]);
      });

    });

  }
}
