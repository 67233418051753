import { Component, OnInit } from '@angular/core';
import {EventDistributerService} from '../../../../event-distributer.service';

@Component({
  selector: 'app-voice-recognizer',
  templateUrl: './voice-recognizer.component.html',
  styleUrls: ['./voice-recognizer.component.scss']
})
export class VoiceRecognizerComponent implements OnInit {

  interimText = new Array<any>();
  intents = new Array<any>();

  constructor(private events: EventDistributerService,) { }

  ngOnInit() {
    this.events.listen().subscribe((event) => {

      if (event.message && event.message.interimText) {
        this.interimText.push(event.message.interimText);
      } else if (event.message && event.message.intent) {
        this.intents.push(event.message);
        setTimeout(() => {
          this.interimText = new Array<any>();
        },2000);
      }

    });

  }

}
