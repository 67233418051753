import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatUIComponentsModule} from './mat-uicomponents/mat-uicomponents.module';
import {RouterModule} from '@angular/router';
import {FlexLayoutModule} from '@angular/flex-layout';
import {SafePipe} from './safe.pipe';
import {AgentGuidanceComponent} from './agent-guidance/agent-guidance.component';
import {CustomerJourneyComponent} from './customer-journey/customer-journey.component';
import {HttpClientModule} from '@angular/common/http';

import {PubNubAngular} from 'pubnub-angular2';
import {MainContentComponent} from './agent-assistant/components/main-content/main-content.component';
import {SideContentComponent} from './agent-assistant/components/side-content/side-content.component';
import {ToolBarComponent} from './agent-assistant/components/tool-bar/tool-bar.component';
import {CtiBarComponent} from './agent-assistant/components/cti-bar/cti-bar.component';
import {AgentAssistantComponent} from './agent-assistant/agent-assistant.component';
import {ContextComponent} from './agent-assistant/components/side-content/context/context.component';
import {JourneyComponent} from './agent-assistant/components/side-content/journey/journey.component';
import {KnowledgeComponent} from './agent-assistant/components/side-content/knowledge/knowledge.component';
import {QuickLinksComponent} from './agent-assistant/components/side-content/quick-links/quick-links.component';
import {NotesComponent} from './agent-assistant/components/side-content/notes/notes.component';
import { AssistanceComponent } from './agent-assistant/components/side-content/assistance/assistance.component';
import { RecommendationsComponent } from './agent-assistant/components/recommendations/recommendations.component';
import { NgMatSearchBarModule } from 'ng-mat-search-bar';
import { VoiceRecognizerComponent } from './agent-assistant/components/side-content/voice-recognizer/voice-recognizer.component';
import { SimpleVoiceRecognizerComponent } from './simple-voice-recognizer/simple-voice-recognizer.component';
import { HiddenAutomationComponent } from './agent-assistant/components/hidden-automation/hidden-automation.component';
import {HostedIFrameComponent} from './agent-assistant/components/hosted-iframe/hosted-iframe.component';

const routes = [
  {
    path: 'agent/:spui',
    component: AgentAssistantComponent
  }, {
    path: 'test',
    component: NotesComponent
  }, {
    path: 'journey/:conversationId',
    component: CustomerJourneyComponent
  },
  {
    path: 'agentassistant/:spui',
    component: AgentAssistantComponent
  },
  {
    path: 'voice-recognizer',
    component: SimpleVoiceRecognizerComponent
  },
  {
    path: '**',
    component: AppComponent
  }
];


@NgModule({
  declarations: [
    AppComponent,
    SafePipe,
    AgentGuidanceComponent,
    CustomerJourneyComponent,
    MainContentComponent,
    SideContentComponent,
    ToolBarComponent,
    CtiBarComponent,
    AgentAssistantComponent,
    ContextComponent,
    JourneyComponent,
    KnowledgeComponent,
    QuickLinksComponent,
    NotesComponent,
    AssistanceComponent,
    RecommendationsComponent,
    VoiceRecognizerComponent,
    SimpleVoiceRecognizerComponent,
    HiddenAutomationComponent,
    HostedIFrameComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatUIComponentsModule,
    FlexLayoutModule,
    HttpClientModule,
    RouterModule.forChild(routes),
    NgMatSearchBarModule
  ],
  providers: [
    PubNubAngular
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
