import {Component, OnInit} from '@angular/core';
import {EventDistributerService} from '../../../../event-distributer.service';
import {ActivatedRoute} from '@angular/router';
import {CacheService} from '../../../../cache.service';
import {SimpleCacheService} from '../../../../simple-cache.service';

@Component({
  selector: 'app-journey',
  templateUrl: './journey.component.html',
  styleUrls: ['./journey.component.scss']
})
export class JourneyComponent implements OnInit {

  baseUrl = 'https://utilities1.gointeract.io/demosites/chatbot/index.html?apiKey=3126dbc6-049c-4b0b-ba74-6fefcf436ef6&environment=Dev&tenant=democenter_df&domainName=DCM_Insurance&initialQuestion=DCM%20Insurance%20customer&cssProject=5b1e76737b9d3140303d868a&spui=thomas&mode=history&chatSessionId=';
  //baseUrl = 'http://localhost:4000/index.html?apiKey=3126dbc6-049c-4b0b-ba74-6fefcf436ef6&environment=Dev&tenant=democenter_df&domainName=DCM_Insurance&initialQuestion=DCM%20Insurance%20customer&cssProject=5b1e76737b9d3140303d868a&spui=thomas&mode=history&chatSessionId=';
  urlToShow: any;

  constructor(private events: EventDistributerService,
              private simpleCache: SimpleCacheService) {
  }

  ngOnInit() {
    this.events.listen().subscribe((event) => {
      if (event.name === 'IncomingCall') {


        if (event.data.serviceCodeData.botHistoryUrl) {

          this.urlToShow = event.data.serviceCodeData.botHistoryUrl;

        } else {

          this.simpleCache.get(event.data.serviceCodeData.conversationId).then((data) => {

            if (data.botHistoryUrl) {
              this.urlToShow = data.botHistoryUrl.replace('&amp;', '&');
            } else {
              console.warn('not enough information to show bot history. Make sure the intent flow is like five9 or file claim');
            }

          });
        }

      }
    });
  }

}
