import {Component, OnInit} from '@angular/core';
import {EventDistributerService} from '../../../../event-distributer.service';
import {animate, style, transition, trigger} from '@angular/animations';
import {MatSnackBar} from '@angular/material';

@Component({
  selector: 'app-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.scss'],
  animations: [
    trigger('items', [
      transition(':enter', [
        style({transform: 'scale(0.5)', opacity: 0}),  // initial
        animate('0.5s cubic-bezier(.8, -0.6, 0.2, 1.5)',
          style({transform: 'scale(1)', opacity: 1}))  // final
      ])
    ])
  ]
})
export class NotesComponent implements OnInit {

  userNotes = '';
  systemNotes = new Array<any>();

  constructor(private events: EventDistributerService,
              private snack: MatSnackBar) {
  }

  ngOnInit() {

    this.events.listen().subscribe((event) => {

      if (event.message) {
        event = event.message;
      }

      if (event.name === 'AddNote' && event.data.noteType === 'system') {
        this.systemNotes.push(event.data);
        this.snack.open('Added system notes', '', {
          duration: 1000,
          verticalPosition: 'top'
        });
      } else if (event.name === 'AddNote' && event.data.noteType === 'user') {
        this.userNotes += '\n' + event.data.text;
        this.snack.open('Added user notes', '', {
          duration: 1000,
          verticalPosition: 'top'
        });
      }

    });

/*

    setInterval(() => {
      this.systemNotes.push({
        system: 'Guidance',
        text: 'some system generated text',
      });
    }, 2000);
*/

  }

}
