import { Component, OnInit } from '@angular/core';
import {EventDistributerService} from '../../../event-distributer.service';

@Component({
  selector: 'app-hidden-automation',
  templateUrl: './hidden-automation.component.html',
  styleUrls: ['./hidden-automation.component.scss']
})
export class HiddenAutomationComponent implements OnInit {

  public frameUrl = '';

  constructor(public events: EventDistributerService) { }

  ngOnInit() {

    this.events.listen().subscribe((event) => {

      this.frameUrl = event.message;

    });

  }

}
