import {Component, OnInit} from '@angular/core';
import {EventDistributerService} from '../../../../event-distributer.service';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-assistance',
  templateUrl: './assistance.component.html',
  styleUrls: ['./assistance.component.scss']
})
export class AssistanceComponent implements OnInit {

  baseUrl = 'https://utilities1.gointeract.io/demosites/chatbot/index.html?apiKey=3126dbc6-049c-4b0b-ba74-6fefcf436ef6&environment=Dev&tenant=democenter_df&domainName=DCM_Insurance&cssProject=5c0668605c9ad00035777cb9&initialQuestion=MSR&agentAssistant=true&clientConversationId=';

  urlToShow: any;

  constructor(
    private events: EventDistributerService,
    private activatedRoute: ActivatedRoute,
  ) {

  }

  ngOnInit() {

    this.events.listen().subscribe((event) => {
      console.log(event);
      if (event.name === 'IncomingCall') {

        if (event.data.serviceCodeData.assistantUrl) {
          this.urlToShow = event.data.serviceCodeData.assistantUrl + '&spui=' + event.data.serviceCodeData.spui + '&clientConversationId=' + event.data.serviceCodeData.conversationId;
        } else {
          this.urlToShow = this.baseUrl + '&spui=' + event.data.serviceCodeData.spui + '&clientConversationId=' + event.data.serviceCodeData.conversationId;
        }
      }
    });
  }

}
