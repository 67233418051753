import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SentimentService {

  private baseUrl = 'https://utilities1.gointeract.io/interact_ai/api/ChatSessionWrappers/bySessionId?sessionId=';

  constructor(
    private http: HttpClient
  ) {

  }

  getSentimentValues(conversationId: string): Promise<any> {

    return new Promise((resolve, reject) => {

      this.http.get(this.baseUrl + conversationId).subscribe((res: any) => {

        res.chatDataList.map((data) => {
          if (data.key === 'sentiment_indicator') {
            resolve(data.value);
          }
        });

        reject('Sentiment indicator not found');

      });


    });


  }


}
