import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {EventDistributerService} from '../../../event-distributer.service';
import {animate, state, style, transition, trigger} from '@angular/animations';


@Component({
  selector: 'app-side-content',
  templateUrl: './side-content.component.html',
  styleUrls: ['./side-content.component.scss'],
  animations: [
    trigger('showHide', [
      // ...
      state('show', style({
        width: '35%',
      })),
      state('hide', style({
        width: '0%',
        display: 'none'
      })),

      transition('show => hide', [
        animate('0.5s')
      ]),
      transition('hide => show', [
        animate('0.5s')
      ]),
    ]),
  ],
})
export class SideContentComponent implements OnInit {

  display: '';

  constructor(
    private activatedRoute: ActivatedRoute,
    private events: EventDistributerService
  ) {
    this.display = '';
  }

  ngOnInit() {

    this.events.listen().subscribe((event) => {
      if (event.name && event.name.indexOf('Clicked') > 0) {

        this.display = event.name;

      }
    });

  }


  showHide(element: string): boolean {

    if(this.display.indexOf(element) > -1) {
      return true;
    }

    return false;
  }


}
