import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {
  trigger,
  state,
  style,
  animate,
  transition,
  // ...
} from '@angular/animations';
import {MatIconRegistry} from '@angular/material';
import {DomSanitizer} from '@angular/platform-browser';
import {ConversationTriggerService} from '../conversation-trigger.service';

@Component({
  selector: 'app-agent-guidance',
  templateUrl: './agent-guidance.component.html',
  styleUrls: ['./agent-guidance.component.scss'],
  animations: [
    trigger('openClose', [
      // ...
      state('open', style({
        width: '35%',
      })),
      state('closed', style({
        width: '0%'
      })),

      state('showIframe', style({
        display: 'block',
      })),

      state('hideIframe', style({
        display: 'none'
      })),

      transition('open => closed', [
        animate('0.5s')
      ]),
      transition('closed => open', [
        animate('0.5s')
      ]),

      transition('hideIframe => showIframe', [
        animate('0.5s')
      ]),
      transition('showIframe => hideIframe', [
        animate('0.5s')
      ]),
    ]),
    trigger('items', [
      transition(':enter', [
        style({transform: 'scale(0.5)', opacity: 0}),  // initial
        animate('1s cubic-bezier(.8, -0.6, 0.5, 1.5)',
          style({transform: 'scale(1)', opacity: 1}))  // final
      ])
    ]),
  ],

})
export class AgentGuidanceComponent implements OnInit {

  loading = false;
  isOpen = true;

  baseUrl = 'https://utilities1.gointeract.io/demosites/chatbot/index.html?apiKey=3126dbc6-049c-4b0b-ba74-6fefcf436ef6&environment=Dev&tenant=democenter_df&domainName=DCM_Insurance&cssProject=5c0668605c9ad00035777cb9&clientConversationId=';
  // baseUrl = 'http://localhost:4000/index.html?apiKey=3126dbc6-049c-4b0b-ba74-6fefcf436ef6&environment=Prod&tenant=democenter_df&domainName=DCM_Insurance&cssProject=5c0668605c9ad00035777cb9&clientConversationId=';


  emptyUrl = 'about:blank';
  urlToShow: any;
  guidanceUrlToShow: any;

  conversationId: string;

  baseUrlGuidance = 'https://gointeract.io/interact/index?interaction=15cdc7ec5548-94b9d48e45fdd67e-1371&accountId=democenter_df&loadFrom=CDN&appkey=dcab776b-0045-4ce8-b759-f55ed07d8875&Environment-Name=Dev&customerMobileNumber=%2B4915112136270&ani=+4915112136270&dnis=+4932211001857&conversationId=';

  mode = true;
  animate: any;

  recoginzedIntents: Array<any>;


  intervallIcon = 750;
  stopIntervallIcon = 5000;
  triggerAnimation = 60000;
  addFirstIntent = 500;
  addSecondIntent = 15000;


  constructor(
    private _activatedRoute: ActivatedRoute,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private conversationService: ConversationTriggerService
  ) {

    this.conversationId = this._activatedRoute.snapshot.paramMap.get('conversationId');
    this.guidanceUrlToShow = this.baseUrlGuidance + this.conversationId;
    this.urlToShow = this.emptyUrl;

    this.recoginzedIntents = new Array<any>();

    this.matIconRegistry.addSvgIcon(
      'neutral',
      this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/neutral.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'happy',
      this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/happy.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'sad',
      this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/sad.svg')
    );


  }


  ngOnInit(): void {
    //
    setTimeout(() => {
      this.animateAssistantInfo();
    }, this.triggerAnimation);


    window.document['notifyConversationId'] = function (event) {
      console.log('Got notifyConversationId event %s', JSON.stringify(event));
      window.document['agentConversationId'] = event.sessionId;
    };


  }


  guidanceManuallyTriggered(event): void {
    this.isOpen = !this.isOpen;

    clearInterval(this.animate);
    this.mode = true;

  }

  animationDone(event: any) {

    if (event.fromState !== 'void' && event.phaseName === 'done') {

      if (this.urlToShow.indexOf(this.baseUrl) !== 0) {
        this.urlToShow = this.baseUrl + this.conversationId + '&initialQuestion=MSR';
      }

    }

    setTimeout(() => {
      clearInterval(this.animate);
      this.mode = true;
    }, this.stopIntervallIcon);


  }

  animateAssistantInfo(): void {
    this.animate = true;
    this.isOpen = true;

    this.animate = setInterval(() => {
      this.mode = !this.mode;
    }, this.intervallIcon);


    // show first intent
    setTimeout(() => {
      this.recoginzedIntents.push({
        label: 'What is my deductible?',
        trigger: 'what is my deductible',
        sentiment: 0
      });
    }, this.addFirstIntent);


    setTimeout(() => {
      // show second intent

      this.recoginzedIntents.push({
        label: 'Coverage for a replacement vehicle?',
        trigger: 'Coverage for a replacement vehicle?',
        sentiment: 0
      });

    }, this.addSecondIntent);

  }

  startIntent(intent: any) {

    this.conversationService.sendText(window.document['agentConversationId'], intent.label, false).subscribe(() => {

      console.log('Send text %s to %s', intent.label, window.document['agentConversationId']);

      this.conversationService.sendHiddenText(window.document['agentConversationId'], intent.trigger).subscribe(() => {
        console.log('Send hidden text %s to %s', intent.trigger, window.document['agentConversationId']);
      });

    });
  }
}
