import {Component, OnInit} from '@angular/core';
import {animate, style, transition, trigger} from '@angular/animations';
import {EventDistributerService} from '../../../event-distributer.service';
import {ConversationTriggerService} from '../../../conversation-trigger.service';

var CrossStorageClient = require('cross-storage').CrossStorageClient;

@Component({
  selector: 'app-recommendations',
  templateUrl: './recommendations.component.html',
  styleUrls: ['./recommendations.component.scss'],
  animations: [
    trigger('items', [
      transition(':enter', [
        style({transform: 'scale(0.5)', opacity: 0}),  // initial
        animate('1s cubic-bezier(.8, -0.6, 0.2, 1.5)',
          style({transform: 'scale(1)', opacity: 1}))  // final
      ])
    ])
  ]
})
export class RecommendationsComponent implements OnInit {

  recommendations = new Array<any>();
  assistantSessionId: string;

  storage: any;
  interval: any;

  constructor(
    private events: EventDistributerService,
    private conversationService: ConversationTriggerService
  ) {

    this.storage = new CrossStorageClient('https://utilities1.gointeract.io/demosites/hub/hub.html');

    this.storage.onConnect().then(() => {

      console.log('Connected to x-domain storage');

      this.storage.get('agentAssistantConversationId').then((val) => {

        console.log('agentAssistantConversationId %s', val);

        this.storage.del('agentAssistantConversationId').then(() => {
          console.log('Deleted conversationId');
          this.interval = setInterval(() => {
            this.storage.get('agentAssistantConversationId').then((value) => {
              if (value) {
                clearInterval(this.interval);
                console.log('we have the conversationId %s', value);
                this.assistantSessionId = value;
              }
            });
          }, 1000);
        });

      });

    });

  }

  ngOnInit() {
    this.events.listen().subscribe(event => {

      if (event.channel && event.channel.indexOf('Speech Intent Recognizer -') === 0) {

        if (event.message && !event.message.intent) {
          return;
        }

        if (event.message.intent.toLowerCase().indexOf('welcome') > -1) {
          // ignore
        } else {
          let triggeredAlready = false;
          this.recommendations.map((value, index, array) => {
            if (value.intent === event.message.intent) {
              triggeredAlready = true;
            }
          });

          if (!triggeredAlready) {
            this.recommendations.push(event.message);
            this.applyAction(event.message);
          }
        }
      }

    });
  }

  applyAction(recommendation: any): void {

    // trigger hidden
    this.startIntent(recommendation);

  }

  startIntent(event: any) {

    console.log('start intent', this.assistantSessionId);

    if (!event.intent || event.intent === '') {
      console.log('Empty intent');
      return;
    }

    if (event.intent && event.intent.toLowerCase().indexOf('fallback') > -1) {
      console.log('Fallback intent');
      return;
    }

    this.conversationService.sendText(this.assistantSessionId, event.recognizedText, false).subscribe(() => {

      console.log('Send text %s to %s', event.intent, this.assistantSessionId);

      this.conversationService.sendHiddenText(this.assistantSessionId, event.recognizedText).subscribe(() => {
        console.log('Send hidden text %s to %s', event.recognizedText, this.assistantSessionId);
      });


    });
  }


}
