import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-hosted-iframe',
  templateUrl: './hosted-iframe.component.html',
  styleUrls: ['./hosted-iframe.component.scss']
})
export class HostedIFrameComponent implements OnInit {


  @Input()
  hostedIFrame: IHostedIFrame;

  constructor() { }

  ngOnInit() {
  }

}
