import {Component, OnInit} from '@angular/core';
import {WatsonDiscoveryService} from '../../../../watson-discovery.service';
import {EventDistributerService} from '../../../../event-distributer.service';
import {MatSnackBar} from '@angular/material';
import {CacheService} from '../../../../cache.service';
import {ConversationTriggerService} from '../../../../conversation-trigger.service';

@Component({
  selector: 'app-knowledge',
  templateUrl: './knowledge.component.html',
  styleUrls: ['./knowledge.component.scss']
})
export class KnowledgeComponent implements OnInit {

  articles: Array<any>;
  private maxText = 500;

  manualSearch = '';

  searching = false;

  constructor(
    private discoveryService: WatsonDiscoveryService,
    private events: EventDistributerService,
    private snackbar: MatSnackBar,
    private cache: CacheService,
    private conversationTriggerService: ConversationTriggerService,
  ) {

  }

  ngOnInit() {

    this.events.listen().subscribe((event) => {
      if (event.message && event.message.name === 'KnowledgeSearch') {
        this.search(event.message.query);

        this.snackbar.open('Knowledge Search Triggered', 'OK', {
          duration: 1500
        });

      }

    });

    /*
        const data = require('./testData.json');
        this.articles = this.filter(data.results);
    */
  }

  search(query: string) {

    this.searching = true;

    this.discoveryService.query(query).then((res: any) => {
      this.articles = this.filter(res.results);

      // indicate we found something
      this.events.emit({
        name: 'highlight',
        button: 'knowledge'
      });

    });

  }

  articleClicked(article: any, mode: string) {

    switch (mode) {
      case 'new':
        window.open(article.metadata.source.url, 'jca_knowledge');
        break;
      case 'embedded':
        this.events.emit({
          name: 'showKnowledgeArticle',
          url: article.metadata.source.url
        });
        break;
      case 'customer':

        const interactionEvent = this.cache.get('InteractionEvent');

        const linkText = 'Here is some interesting information for you: Click here <a href="' + article.metadata.source.url + '">' + article.extracted_metadata.title + ' </a>';

        const objectObservable = this.conversationTriggerService.sendText(interactionEvent.serviceCodeData.conversationId, linkText, true);
        objectObservable.subscribe();

        break;
    }


  }


  filter(result: Array<any>): Array<any> {

    let cache = {};

    let res = new Array<any>();

    result.map((article) => {

      if (!cache[article.extracted_metadata.title]) {
        cache[article.extracted_metadata.title] = article;

        if (article.text.length > this.maxText) {
          article.text = article.text.substring(0, this.maxText);
          article.text += '...';
        }
        res.push(article);
      }

    });

    this.searching = false;

    return res;

  }

  searchChanged(keyEvent: any): void {
    if (keyEvent.key === 'Enter') {

      this.search(this.manualSearch);

    }
  }

}
