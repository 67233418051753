import {Component, OnInit} from '@angular/core';
import {EventDistributerService} from '../event-distributer.service';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {ActivatedRoute} from '@angular/router';
import {debug} from 'util';

@Component({
  selector: 'app-agent-assistant',
  templateUrl: './agent-assistant.component.html',
  styleUrls: ['./agent-assistant.component.scss'],
  animations: [
    trigger('openClose', [
      // ...
      state('open', style({
        width: '30%',
        display: 'block'
      })),
      state('closed', style({
        width: '0%',
        display: 'none'
      })),

      transition('open => closed', [
        animate('0.5s')
      ]),
      transition('closed => open', [
        animate('0.5s')
      ]),

    ])
  ],
})
export class AgentAssistantComponent implements OnInit {

  activated = false;

  isOpen = true;

  constructor(
    private events: EventDistributerService,
    private activatedRoute: ActivatedRoute,
  ) {

  }

  ngOnInit() {

    this.events.listen().subscribe((event) => {
      if (event.name === 'simulate') {
        this.activated = true;
      } else if (event.name === 'expand') {
        this.isOpen = true;
      } else if (event.name === 'collapse') {
        this.isOpen = false;
      }
    });

    setTimeout(() => {
      if (this.activatedRoute.snapshot.paramMap.get('spui').length < 10) {
        this.isOpen = false;
      }

    }, 500);

  }


  hideCti(): boolean {

    return this.activatedRoute.snapshot.paramMap.get('spui').length > 10;

  }



}
