import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {SentimentService} from '../sentiment.service';
import {MatIconRegistry, MatSnackBar} from '@angular/material';
import {DomSanitizer} from '@angular/platform-browser';
import {animate, state, style, transition, trigger} from '@angular/animations';


export enum AgentTools {
  None = 'none',
  VariableSet = 'variableSet',
  CustomerView = 'customerView'
}


@Component({
  selector: 'app-customer-journey',
  templateUrl: './customer-journey.component.html',
  styleUrls: ['./customer-journey.component.scss'],
  animations: [
    trigger('openClose', [
      // ...
      state('open', style({
        width: '35%',
      })),
      state('closed', style({})),

      state('showIframe', style({
        display: 'block',
      })),

      state('hideIframe', style({
        display: 'none'
      })),

      transition('open => closed', [
        animate('0.5s')
      ]),
      transition('closed => open', [
        animate('0.5s')
      ]),

      transition('hideIframe => showIframe', [
        animate('0.5s')
      ]),
      transition('showIframe => hideIframe', [
        animate('0.5s')
      ]),


    ]),
  ],
})
export class CustomerJourneyComponent implements OnInit {

  isOpen = false;

  baseJourneyUrl = 'https://utilities1.gointeract.io/demosites/chatbot/index.html?mode=history&cssProject=5c0668605c9ad00035777cb9&chatSessionId=';
  historyToShow: any;
  conversationId: string;
  sentimentValue: -2;
  loading = false;
  panelOpenState = false;
  display = AgentTools.None;
  uniqueInteractionId : string;

  public AgentTools = AgentTools;

  constructor(
    private _activatedRoute: ActivatedRoute
    , private _sentimentService: SentimentService
    , private matIconRegistry: MatIconRegistry
    , private domSanitizer: DomSanitizer
    , private _snackBar: MatSnackBar
  ) {

    this.conversationId = this._activatedRoute.snapshot.paramMap.get('conversationId');
    this.historyToShow = this.baseJourneyUrl + this.conversationId;

    this.matIconRegistry.addSvgIcon(
      'neutral',
      this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/neutral.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'happy',
      this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/happy.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'sad',
      this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/sad.svg')
    );

  }

  ngOnInit() {

    this.loading = true;

    this._sentimentService.getSentimentValues(this.conversationId).then((res) => {
      // do something
      this.sentimentValue = res;
    }).catch((err) => {
      console.log(err);
    }).finally(() => {
      this.loading = false;
    });


    this._activatedRoute.queryParams.subscribe((param) => {
      if(param.uniqueInteractionId) {
        this.uniqueInteractionId = param.uniqueInteractionId;
      }
    });

  }

  guidanceManuallyTriggered(showWhat: AgentTools): void {

    switch (showWhat) {
      case AgentTools.VariableSet:

        break;

      case AgentTools.CustomerView:

        break;
    }

    this.isOpen = this.display !== showWhat;

    this.display = showWhat;

  }

  animationDone(event: any) {

    /*

    if (event.fromState !== 'void' && event.phaseName === 'done') {

      this._snackBar.open('We can load some details here.', 'OK', {
        duration: 1500,
        panelClass: 'info'
      });
    }

    */

  }

}
